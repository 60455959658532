const initialState = {
  categoryFeaturedPostList: [],
  loading: false,
  error: false,
  suggestions: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "CONTENT_CONFIG_CATEGORY_POST_REQUEST_DATA":
      return {
        ...state,
        loading: true,
        error: false,
      };
    case "CONTENT_CONFIG_CATEGORY_POST_FAIL_REQUEST":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case "CONTENT_CONFIG_CATEGORY_POST_LIST":
      return {
        ...state,
        loading: false,
        error: false,
        categoryFeaturedPostList: payload.data,
      };
    case "GET_SUGGESTIONS_CATEGORY_POST":
      return {
        ...state,
        loading: false,
        error: false,
        suggestions: payload.data,
      };
    case "CLEAR_SUGGESTIONS_CATEGORY_POST":
      return {
        ...state,
        suggestions: [],
      };
    default:
      return state;
  }
};
