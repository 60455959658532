import API from "../../helpers/api";
import { toastr } from "react-redux-toastr";
import { get } from "lodash";

export const REQUEST_MANAGE_POST = "REQUEST_MANAGE_POST";
export const RECEIVE_MANAGE_POST = "RECEIVE_MANAGE_POST";
export const RECEIVE_MANAGE_TRASH_POST = "RECEIVE_MANAGE_TRASH_POST";
export const FAILURE_MANAGE_POST = "FAILURE_MANAGE_POST";
export const STATUS_CHANGE_POST = "STATUS_CHANGE_POST";
export const CATEGORY_LIST_MANAGE_POST = "CATEGORY_LIST_MANAGE_POST";
export const SUBCATEGORY_LIST_MANAGE_POST = "SUBCATEGORY_LIST_MANAGE_POST";
export const COLLECTION_LIST_MANAGE_POST = "COLLECTION_LIST_MANAGE_POST";
export const TAGS_LIST_MANAGE_POST = "TAGS_LIST_MANAGE_POST";
export const RECEIVE_POST_DETAILS = "RECEIVE_POST_DETAILS";
export const REQUEST_POST_DETAILS = "REQUEST_POST_DETAILS";
export const SAVE_MANAGE_POST = "SAVE_MANAGE_POST";
export const SET_PAGE_NUMBER_MANAGE_POST = "SET_PAGE_NUMBER_MANAGE_POST";
export const SET_SEARCH_PARAM_MANAGE_POST = "SET_SEARCH_PARAM_MANAGE_POST";
export const EMPTY_SUBCATEGORY_LIST = "EMPTY_SUBCATEGORY_LIST";
export const REQUEST_MANAGE_LIST = "REQUEST_MANAGE_LIST";

function requestData() {
  return {
    type: REQUEST_MANAGE_POST,
  };
}

function requestPostList() {
  return {
    type: REQUEST_MANAGE_LIST,
  };
}

function requestDetails() {
  return {
    type: REQUEST_POST_DETAILS,
  };
}

function requestFailure(err) {
  return {
    type: FAILURE_MANAGE_POST,
    payload: err || "API failure",
  };
}

function errorOccured(err) {
  let data = get(err, "response.data", null);
  data = !!data ? data : get(err, "response");
  data = !!data ? data : err;
  toastr.error(
    "TRANSFIN. Admin Panel  ",
    data.message ? data.message : "Manage Post Request Failed! "
  );

  return (dispatch, getState) => {
    dispatch({
      type: FAILURE_MANAGE_POST,
      payload: err,
    });
  };
}

export const fetchPostDetail = (requestedUrl) => (dispatch) => {
  dispatch(requestDetails());
  API.apiGetByKey("fetchPostDetail", `${requestedUrl}`)
    .then((response) => {
      if (response.data && response.data.status == "SUCCESS") {
        let data=response.data.data;
        dispatch({
          type: RECEIVE_POST_DETAILS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      dispatch(requestFailure(error));
      errorOccured(error);
    });
};

export const fetchManagePostList = (requestedParams) => (dispatch) => {
  dispatch(requestPostList());

  API.apiGet("getArticlePostList", requestedParams)
    .then((response) => {
      if (response.data && response.data.status == "SUCCESS") {
        dispatch({
          type: RECEIVE_MANAGE_POST,
          payload: response.data.data,
        });
      }
    })
    .catch((error) => {
      dispatch(requestFailure(error));
      errorOccured(error);
    });
};

export const fetchManagePostTrashList = (requestedParams) => (dispatch) => {
  dispatch(requestPostList());

  API.apiGet("getArticlePostTrashList", requestedParams)
    .then((response) => {
      if (response.data && response.data.status == "SUCCESS") {
        dispatch({
          type: RECEIVE_MANAGE_TRASH_POST,
          payload: response.data.data,
        });
      }
    })
    .catch((error) => {
      dispatch(requestFailure(error));
      errorOccured(error);
    });
};

export const saveDraftPostDetail = (requestedPayload, next) => (dispatch) => {
  dispatch(requestData());

  API.apiPost("saveAsDraftPost", requestedPayload)
    .then((response) => {
      if (response.data && response.data.status == "SUCCESS") {
        next(response.data);
        dispatch({
          type: SAVE_MANAGE_POST,
          payload: response.data.data,
        });
      }
    })
    .catch((error) => {
      next();
      errorOccured(error);
    });
};

export const updateManagePostStatus = (reqData, next) => (dispatch) => {
  dispatch(requestData());

  API.apiPost("updateArticlesStatus", reqData)
    .then((response) => {
      if (response.data && response.data.status == "SUCCESS") {
        next(response.data);
        // dispatch({
        //   type: RECEIVE_MANAGE_POST,
        //   payload: response.data.data,
        // });
      }
    })
    .catch((error) => {
      next();
      dispatch(requestFailure(error));
      errorOccured(error);
    });
};

export const isPostEditable = (url, next) => (dispatch) => {
  API.apiGet("isPostEditable", `?url=${url}`)
    .then((response) => {
      if (response.data && response.data.status == "SUCCESS") {
        next(response.data?.data);
      }
    })
    .catch((error) => {
      next();
      errorOccured(error);
    });
};

export const changeArticlesStatus = (reqData, next) => (dispatch) => {
  dispatch(requestData());

  API.apiPut("changeArticlesStatus", reqData)
    .then((response) => {
      if (response.data && response.data.status == "SUCCESS") {
        next(response.data);
      }
    })
    .catch((error) => {
      next();
      dispatch(requestFailure(error));
      errorOccured(error);
    });
};

export const fetchCategoryList = () => (dispatch) => {
  dispatch(requestData());

  API.apiGet("getCategoryListForHeaderMenu")
    .then((response) => {
      if (response.data && response.data.status == "SUCCESS") {
        dispatch({
          type: CATEGORY_LIST_MANAGE_POST,
          payload: response.data,
        });
      }
    })
    .catch((error) => {
      errorOccured(error);
    });
};

export const fetchSubCategoryList = (categoryId) => (dispatch) => {
  dispatch(requestData());
  if (!categoryId) return;

  API.apiGet("getSubcategoryByCategory", `?category=${categoryId}`)
    .then((response) => {
      if (response.data && response.data.status == "SUCCESS") {
        dispatch({
          type: SUBCATEGORY_LIST_MANAGE_POST,
          payload: response.data,
        });
      }
    })
    .catch((error) => {
      errorOccured(error);
    });
};

export const fetchTagsList = () => (dispatch) => {
  dispatch(requestData());

  API.apiGet("tagList")
    .then((response) => {
      if (response.data && response.data.status == "SUCCESS") {
        dispatch({
          type: TAGS_LIST_MANAGE_POST,
          payload: response.data,
        });
      }
    })
    .catch((error) => {
      errorOccured(error);
    });
};

export const fetchCollectionList = () => (dispatch) => {
  dispatch(requestData());

  API.apiGet("collectionList")
    .then((response) => {
      if (response.data && response.data.status == "SUCCESS") {
        dispatch({
          type: COLLECTION_LIST_MANAGE_POST,
          payload: response.data,
        });
      }
    })
    .catch((error) => {
      errorOccured(error);
    });
};

export const emptySubCategories = () => (dispatch) => {
  dispatch({ type: "EMPTY_SUBCATEGORY_LIST" });
};
