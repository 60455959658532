import moment from 'moment';

const initialState = {
  commentsList: [],
  loading: false,
  error: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "COMMENTS_REQUEST_DATA":
      return {
        ...state,
        loading: true,
        error: false,
      };
    case "COMMENTS_FAIL_REQUEST":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case "GET_COMMENTS_DATA":
      return {
        ...state,
        loading: false,
        error: false,
        commentsList: dataParser(payload.data.docs),
      };
    default:
      return state;
  }
};

const dataParser = (data) => {
  return data.map((item, i) => ({
    index: i + 1,
    _id: item._id,
    comment: item.comment,
    url: item.article?.url,
    postTitle: item.article?.title,
    postCode: item.article?.code,
    noOfRecommends: item.likes?.length,
    userName: item.createdBy?.firstName,
    userEmail: item.createdBy?.email,
    status: item.status,
    createdOn: item.createdOn && moment.unix(item.createdOn).format("lll"),
    modifiedOn: item.modifiedOn && moment.unix(item.modifiedOn).format("lll"),
  }));
};
