const initialState = {
  homePageBodyList: [],
  categoryList: [],
  subCategoryList: [],
  total: 0,
  searchParam: {},
  page: 1,
  loading: false,
  error: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "REQUEST_HOMEPAGE_BODY_DATA":
      return {
        ...state,
        loading: true,
      };
    case "FAILURE_HOMEPAGE_BODY":
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case "RECEIVE_HOMEPAGE_BODY":
      return {
        ...state,
        homePageBodyList: payload.data.content.map((item) => ({
          ...item,
          selectedValue: `${item.name || item.title},${item._id || item.id},${
            item.url || ""
          }`,
        })),
      };
    case "HOMEPAGE_BODY_CATEGORY_LIST":
      return {
        ...state,
        loading: false,
        error: false,
        categoryList: dataSorter(payload.data),
      };
    case "HOMEPAGE_BODY_SUBCATEGORY_LIST":
      return {
        ...state,
        loading: false,
        error: false,
        subCategoryList: dataSorter(payload.data),
      };
    default:
      return state;
  }
};

function dataSorter(data) {
  data.unshift({ name: "Select Type", value: "" });
  return data.map((item) => ({
    name: item.name || item.title,
    value: `${item.name || item.title},${item._id || item.id},${
      item.url || ""
    }`,
    url: item.url || undefined,
    id: item._id || item.id || undefined,
  }));
}
