import API from "../../helpers/api";
import { toastr } from "react-redux-toastr";
import { get } from "lodash";

export const REQUEST_COLLECTION = "REQUEST_COLLECTION";
export const RECEIVE_COLLECTION = "RECEIVE_COLLECTION";
export const FAILURE_COLLECTION = "FAILURE_COLLECTION";

function requestCategory() {
  return {
    type: REQUEST_COLLECTION,
  };
}
function errorCategory(err) {
  let data = get(err, "response.data", null);
  data = !!data ? data : get(err, "response");
  data = !!data ? data : err;
  toastr.error(
    "TRANSFIN. Admin Panel  ",
    data.message ? data.message : "Category Request Failed! "
  );

  return (dispatch, getState) => {
    dispatch({
      type: FAILURE_COLLECTION,
      payload: err,
    });
  };
}

export function fetchCollectionList(requestedParams) {
  return (dispatch, getState) => {
    dispatch(requestCategory());

    API.apiGet("seriesList", requestedParams)
      .then((response) => {
        if (response.data.status == "SUCCESS") {
          dispatch({ type: "RECEIVE_COLLECTION", payload: response.data });
        }
      })
      .catch((error) => {
        errorCategory(error)(dispatch);
      });
  };
}

export function addCollection(requestedData) {
  return (dispatch, getState) => {
    dispatch(requestCategory());

    API.apiPost("createCollection", requestedData)
      .then((response) => {
        if (response.data.status == "SUCCESS") {
          dispatch({ type: "CREATE_COLLECTION", payload: response.data });
        }
      })
      .catch((error) => {
        errorCategory(error)(dispatch);
      });
  };
}

export function updateCollectionId(requestedId, updateData) {
  return (dispatch, getState) => {
    dispatch(requestCategory());

    API.apiPutUrl("updateDelCollectionById", requestedId, updateData)
      .then((response) => {
        if (response.data.status == "SUCCESS") {
          dispatch({ type: "UPDATE_COLLECTION", payload: response.data });
        }
      })
      .catch((error) => {
        errorCategory(error)(dispatch);
      });
  };
}

export function deleteCollectionId(requestedIds, cb) {
  return (dispatch, getState) => {
    dispatch(requestCategory());

    API.apiPut("changeStatusCollection", requestedIds)
      .then((response) => {
        if (response.data.status == "SUCCESS") {
          cb && cb("delete");
          dispatch({ type: "DELETE_COLLECTION", payload: response.data });
        }
      })
      .catch((error) => {
        errorCategory(error)(dispatch);
      });
  };
}

export function changeStatusCollectionId(payload, cb) {
  return (dispatch, getState) => {
    dispatch(requestCategory());

    API.apiPut("changeStatusCollection", payload)
      .then((response) => {
        if (response.data.status == "SUCCESS") {
          cb && cb("statusChange");
          dispatch({ type: "STATUS_CHANGE_COLLECTION", payload });
        }
      })
      .catch((error) => {
        errorCategory(error)(dispatch);
      });
  };
}
