import * as moment from "moment";

const initialState = {
  followersList: [],
  loading: false,
  error: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "FOLLOWERS_REQUEST_DATA":
      return {
        ...state,
        loading: true,
        error: false,
      };
    case "FOLLOWERS_FAIL_REQUEST":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case "GET_FOLLOWERS_DATA":
      return {
        ...state,
        loading: false,
        error: false,
        followersList: dataParser(payload.data.docs),
      };
    default:
      return state;
  }
};

const dataParser = (data) => {
  return data.map((item, i) => ({
    index: i + 1,
    _id: item._id,
    follower: item.follower && item.follower.firstName + " " + item.follower.lastName,
    following: item.following && item.following.firstName + " " + item.following.lastName,
    createdOn: item.createdOn && item.createdOn ? moment.unix(item.createdOn).format("lll") : item.createdOn,
  }));
};
