import $ from 'jquery';
import React, { Component } from 'react';
import { correctHeight, detectBody } from '../../theme/helpers/helpers';
import '../../assets/dependencies';
import Slider from '../../theme/slider';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';


export default class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerClass: '',
      active: 'active',
      hover: {
        login: '',
        register: ''
      }
    };
    this.changeHeaderOn = 200;
  }

  componentDidMount() {
    // eslint-disable-next-line func-names
    $(window).bind('load resize', function() {
      correctHeight();
      detectBody();
    });
    window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    return (
      <div className="landing-page gray-bg">
        <div id="wrapper" className="animate">
          <nav className={`navbar header-top fixed-top navbar-expand-lg navbar-light navbar-scroll white-bg ${this.state.headerClass}`}>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" />
            </button>
            <Link className="nav-link" to="">
              <img alt="show" className="img-circle logo" src={logo}/>
            </Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarText">
              <ul className="navbar-nav ml-md-auto d-md-flex">
                <li className="nav-item">
                  <Link className={`nav-link menu-tabs ${this.state.active}`} to="/" id="home">Home</Link>
                </li>
                {/* <li className="nav-item">
                  <Link className={`nav-link menu-tabs ${this.state.hover.register}`} name="register"
                    onMouseEnter={(e) => this.toggleHover(e, 'active')} onMouseLeave={(e) => this.toggleHover(e, '')}
                    to="/register" id="register">Register</Link>
                </li> */}
                <li className="nav-item">
                  <Link className={`nav-link menu-tabs ${this.state.hover.login}`} name="login"
                    onMouseEnter={(e) => this.toggleHover(e, 'active')} onMouseLeave={(e) => this.toggleHover(e, '')}
                    to="/login" id="login">Login</Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>

        <section id="features" className="container services">
          <div className="row">
            <div className="col-sm-3">
              <h2>Develop</h2>
              <p>Develop various categories and versions of API's using friendly developer portal.</p>
            </div>
            <div className="col-sm-3">
              <h2>Demonstrate</h2>
              <p>Demonstrate your API's to consumers over consumer portal </p>
            </div>
            <div className="col-sm-3">
              <h2>Integrate</h2>
              <p>Integrate and test API's at SIT sandbox.</p>
            </div>
            <div className="col-sm-3">
              <h2>Utilize</h2>
              <p>Promote your integration to production and enjoy 24/7 API availability.</p>
            </div>
          </div>
        </section>

       <Slider/>

        <section id="contact" className="gray-section contact">
          <div className="container">
            <div className="row m-b-lg">
              <div className="col-lg-12 text-center">
                <div className="navy-line" />
                <h1>Contact Us</h1>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 text-center">
                <ul className="list-inline social-icon">
                  <li><a href="/app/dashboard"><i className="fa fa-twitter" /></a>
                  </li>
                  <li><a href="/app/dashboard"><i className="fa fa-facebook" /></a>
                  </li>
                  <li><a href="/app/dashboard"><i className="fa fa-linkedin" /></a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-2" />
              <div className="col-lg-8 text-center m-t-lg m-b-lg">
                <p><strong>© 2020 TRANSFIN. All rights reserved</strong></p>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }

  toggleHover = (event, className) => {
    const active = !!className ? '' : 'active';
    this.setState({ hover: { [event.target.name]: className }, active });
  };

  handleScroll = () => {
    const docElem = document.documentElement;

    setTimeout(scrollPage.call(this), 250);

    function scrollPage() {
      const sy = scrollY();
      this.setState({ headerClass: sy >= this.changeHeaderOn ? 'gray-bg' : '' });
    }

    function scrollY() {
      return window.pageYOffset || docElem.scrollTop;
    }
  };
}
