import * as moment from "moment";

const initialState = {
  subscriptionList: [],
  utmList: [],
  subscribersTrackList: [],
  loading: false,
  error: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "SUBSCRIPTION_REQUEST_DATA":
      return {
        ...state,
        loading: true,
        error: false,
      };
    case "SUBSCRIPTION_FAIL_REQUEST":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case "GET_SUBSCRIPTION_DATA":
      return {
        ...state,
        loading: false,
        error: false,
        subscriptionList: dataParser(payload.data),
      };
    case "GET_UTM_DATA":
      return {
        ...state,
        loading: false,
        error: false,
        utmList: payload.data,
      };
    case "GET_SUBSCRIBER_TRACK_DATA":
      return {
        ...state,
        loading: false,
        error: false,
        subscribersTrackList: payload.data,
      };
    default:
      return state;
  }
};

const dataParser = (data) => {
  return data.map((item, i) => ({
    index: i + 1,
    _id: item._id,
    firstName: item.firstName,
    email:item.email,
    phone:item.phone,
    subscriptionId:item.subscriptionId,
    planId:item.planId,
    type:item.type,
    amount:typeof item?.amount === "number" ? item.amount : '-',
    createdOn: item.createdOn && item.createdOn ? moment.unix(item.createdOn).format("lll") : item.createdOn,
    chargeAt:item.chargeAt && item.chargeAt ? moment.unix(item.chargeAt).format("lll") : item.chargeAt,
    currentEnd:item.currentEnd && item.currentEnd ? moment.unix(item.currentEnd).format("lll") : item.currentEnd,
    status:item.status
  }));
};
