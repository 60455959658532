import * as moment from "moment";

const initialState = {
  subscriberList: [],
  loading: false,
  error: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "SUBSCRIBER_REQUEST_DATA":
      return {
        ...state,
        loading: true,
        error: false,
      };
    case "SUBSCRIBER_FAIL_REQUEST":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case "GET_SUBSCRIBER_DATA":
      return {
        ...state,
        loading: false,
        error: false,
        subscriberList: dataParser(payload.data.docs),
      };
    default:
      return state;
  }
};

const dataParser = (data) => {
  return data.map((item, i) => ({
    index: i + 1,
    _id: item._id,
    name: item.name,
    // authorCode:item.authorCode,
    email:item.email,
    notify: typeof item?.notify === "boolean" ? item.notify.toString() : '-',
    retryCount:item.retryCount,
    modifiedOn:item.modifiedOn && item.modifiedOn ? moment.unix(item.modifiedOn).format("lll") :item.modifiedOn,
    createdOn: item.createdOn && item.createdOn ? moment.unix(item.createdOn).format("lll") : item.createdOn,
  }));
};
