const initialState = {
  globalBannerData: [],
  loading: false,
  error: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "CONTENT_CONFIG_GLOBAL_BANNER_REQUEST_DATA":
      return {
        ...state,
        loading: true,
        error: false,
      };
    case "CONTENT_CONFIG_GLOBAL_BANNER_FAIL_REQUEST":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case "CONTENT_CONFIG_GLOBAL_BANNER_DATA":
      return {
        ...state,
        loading: false,
        error: false,
        globalBannerData: payload.data.bannerData,
      };
    default:
      return state;
  }
};
