import moment from "moment";

const initialState = {
  adminList: [],
  contributorsList: [],
  allUsersList: [],
  adminUpdated: false,
  contributorsUpdated: false,
  userType: "",
  userDetails: { item: {}, profile: {}, roles: [] },
  loading: false,
  error: false,
  formSubmitted: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "MANAGE_USERS_REQUEST_DATA":
      return {
        ...state,
        loading: true,
        error: false
      };
    case "MANAGE_USERS_FAIL_REQUEST":
      return {
        ...state,
        loading: false,
        error: payload
      };
    case "SET_USER_TYPE":
      return {
        ...state,
        userType: payload
      };
    case "GET_ADMIN_LIST":
      return {
        ...state,
        loading: false,
        error: false,
        adminList: dataParser(payload.data.docs, "Admin")
      };
    case "GET_CONTRIBUTORS_LIST":
      return {
        ...state,
        loading: false,
        error: false,
        contributorsList: dataParser(payload.data.docs, "Contributors")
      };
    case "GET_ALL_USERS":
      return {
        ...state,
        loading: false,
        error: false,
        allUsersList: payload
      };
    case "GET_USERS_DETAILS":
      return {
        ...state,
        loading: false,
        error: false,
        userDetails: profileParser(payload.data)
      };
    case "CLEAR_USER":
      return {
        ...state,
        loading: false,
        error: false,
        userDetails: {}
      };
    case "UPDATE_SUCCESS":
      return {
        ...state,
        formSubmitted: true
      };
    case "CLEAR_FORM_SUBMITTED":
      return {
        ...state,
        formSubmitted: false
      };
    case "CLOSE_LOADING": {
      return {
        ...state,
        loading: false
      };
    }
    default:
      return state;
  }
};

const dataParser = (data, userType) => {
  const result = Array.from(data).map((item, i) => ({
    index: i + 1,
    _id: item._id,
    firstName: item.firstName || "",
    lastName: item.lastName || "",
    role: userType,
    email: item.email || "",
    mobile: (item.phone && item.phone.toString()) || "",
    addedBy: item.addedBy || "",
    createdOn: item.createdOn && moment.unix(item.createdOn).format("lll"),
    modifiedOn: item.modifiedOn && moment.unix(item.modifiedOn).format("lll"),
    createdBy: item.createdBy && item.createdBy.firstName,
    status: item.status || "",
    authorCode: item.authorCode
  }));
  return result;
};

const profileParser = (data) => {
  const {
    profile ,
    _id,
    firstName,
    lastName,
    email,
    phone,
    newPassword,
    confirmPassword,
    userType,
    roles
  } = data;

  let permissions={}
  let read, write, module, label;
  roles.forEach(item=>{
    read= item.split(':')[0] === 'read';
    write= item.split(':')[0] === 'write';
    module= item.split(':')[0] === 'delete';
    label = item.split(':')[1];
    permissions[label] = { ...permissions[label], label }
    if(read){
      permissions[label]['read']=true
    }else if(write){
      permissions[label]['write']=true
    }else if(module){
      permissions[label]['module']=true
    }
    read = write = module = label = undefined
  })

  return {
    item: {
      _id,
      firstName,
      lastName,
      email,
      phone,
      newPassword,
      confirmPassword,
      userType,
    },
    roles: permissions,
    profile: {
      title: profile ? profile.title: "",
      organization: profile ? profile.organization: "",
      profession: profile ? profile.profession: "",
      dob: profile? profile.dob && moment(profile.dob).isValid() ? profile.dob : "" : "",
      facebookUrl: profile ? profile.facebookUrl: "",
      linkedinUrl: profile ? profile.linkedinUrl: "",
      twitterUrl: profile ? profile.twitterUrl: "",
      city: profile ? profile.city: "",
      country: profile ? profile.country: "",
      aboutMe: profile ? profile.aboutMe: "",
      profilePicUrl: profile ? profile.profilePicUrl?.original: "",
      coverPicUrl: profile ? profile.coverPicUrl?.original: "",
      gender: profile ? profile.gender: "",
    },
  };
};
