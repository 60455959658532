import { get, map, filter, merge } from "lodash";
import moment from 'moment';

const initialState = {
  tagList: [],
  searchTagList:[],
  total: 0,
  searchParam: {},
  page: 1,
  loading: false,
  categoryById: {},
  error: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "RECEIVE_TAG":
      return {
        ...state,
        tagList: dataParser(payload.data.docs),
        total: payload.data.length,
        loading: false,
      };
    case "RECEIVE_SEARCH_TAG":
      return {
        ...state,
        searchTagList: payload.data,
        loading: false,
      };
    case "STATUS_CHANGE_TAG":
      return {
        ...state,
        loading: false,
      };
    case "DELETE_TAG":
      return {
        ...state,
        loading: false,
      };
    case "UPDATE_TAG":
      return {
        ...state,
        loading: false,
      };
    case "CREATE_TAG":
      return {
        ...state,
        loading: false,
      };
    case "REQUEST_TAG":
      return {
        ...state,
        loading: true,
        error: false,
      };
    case "FAILURE_TAG":
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

const updateStatus = (payload, state) => {
  if (payload.data && payload.data._id && state.tagList.length) {
    let list = map(state.tagList, (v) => {
      if (v._id === payload.data._id) {
        return merge(v, payload.data);
      }
      return v;
    });
    return [...list];
  } else {
    console.log("else");
    return [...state.tagList];
  }
};

const dataParser = (data) => {
  return data.map((item, i) => ({
    index: i + 1,
    _id: item._id,
    name: item.name,
    imageUrl: item.imageUrl?.original,
    createdOn: item.createdOn && moment.unix(item.createdOn).format('lll'),
    createdBy: item.createdBy?.firstName,
    modifiedOn: item.modifiedOn && moment.unix(item.modifiedOn).format('lll'),
    modifiedBy: item.modifiedBy?.firstName,
    status: item.status,
    url: item.url,
    description: item.description,
    ogTitle: item.ogTitle,
    seoTitle: item.seoTitle,
    ogDescription: item.ogDescription,
    seoDescription: item.seoDescription,
  }));
};
