import API from "../../helpers/api";
import { toastr } from "react-redux-toastr";
import { get } from "lodash";

export const REQUEST_CATEGORY = "REQUEST_CATEGORY";
export const RECEIVE_CATEGORY = "RECEIVE_CATEGORY";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const GETBY_ID_CATEGORY = "GETBY_ID_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const STATUS_CHANGE_CATEGORY = "STATUS_CHANGE_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const FAILURE_CATEGORY = "FAILURE_CATEGORY";

function requestCategory() {
  return {
    type: REQUEST_CATEGORY,
  };
}
function errorCategory(err) {
  let data = get(err, "response.data", null);
  data = !!data ? data : get(err, "response");
  data = !!data ? data : err;
  toastr.error(
    "TRANSFIN. Admin Panel  ",
    data.message ? data.message : "Category Request Failed! "
  );

  return (dispatch, getState) => {
    dispatch({
      type: FAILURE_CATEGORY,
      payload: err,
    });
  };
}

export function fetchCategoryList(requestedParams) {
  return (dispatch, getState) => {
    dispatch(requestCategory());

    API.apiGet("createListCategory", requestedParams)
      .then((response) => {
        if (response.data.status == "SUCCESS") {
          dispatch({ type: "RECEIVE_CATEGORY", payload: response.data });
        }
      })
      .catch((error) => {
        errorCategory(error)(dispatch)
      });
  };
}

export function addCategoryList(requestedData) {
  return (dispatch, getState) => {
    dispatch(requestCategory());

    API.apiPost("createListCategory", requestedData)
      .then((response) => {
        if (response.data.status == "SUCCESS") {
          dispatch({ type: "CREATE_CATEGORY", payload: response.data });
        }
      })
      .catch((error) => {
        errorCategory(error)(dispatch);
      });
  };
}

export function changeStatusCategoryId(requestedIds) {
  return (dispatch, getState) => {
    dispatch(requestCategory());

    API.apiPut("changeStatusCategory", requestedIds)
      .then((response) => {
        if (response.data.status == "SUCCESS") {
          dispatch({ type: "STATUS_CHANGE_CATEGORY", payload: response.data });
        }
      })
      .catch((error) => {
        errorCategory(error)(dispatch);
      });
  };
}

export function deleteCategoryId(requestedIds) {
  return (dispatch, getState) => {
    dispatch(requestCategory());

    API.apiPut("changeStatusCategory", requestedIds)
      .then((response) => {
        if (response.data.status == "SUCCESS") {
          dispatch({ type: "DELETE_CATEGORY", payload: response.data });
        }
      })
      .catch((error) => {
        errorCategory(error)(dispatch);
      });
  };
}

export function updateCategoryId(requestedId, updateData) {
  return (dispatch, getState) => {
    dispatch(requestCategory());

    API.apiPutUrl("getUpdateDelCategoryById", `/${requestedId}`, updateData)
      .then((response) => {
        if (response.data.status == "SUCCESS") {
          dispatch({ type: "UPDATE_CATEGORY", payload: response.data });
        }
      })
      .catch((error) => {
        errorCategory(error)(dispatch);
      });
  };
}

export function getCategoryId(requestedId) {
  return (dispatch, getState) => {
    dispatch(requestCategory());

    API.apiGet("createListCategory", requestedId)
      .then((response) => {
        if (response.data.status == "SUCCESS") {
          dispatch({ type: "GETBY_ID_CATEGORY", payload: response.data });
        }
      })
      .catch((error) => {
        errorCategory(error)(dispatch);
      });
  };
}

export function fetchCategoryData(requestedParams) {
  return (dispatch, getState) => {
    return dispatch(fetchCategoryList(requestedParams));
  };
}
