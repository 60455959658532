const initialState = {
  countryList: [],
  cityList: [],
  formSubmitted:false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_COUNTRY_LIST":
      return {
        ...state,
        countryList: countryFormatter(payload.docs),
      };
    case "GET_CITY_LIST":
      return {
        ...state,
        cityList: dataParser(payload.docs),
      };
    case "FORM_SUBMITTED":
      return {
        ...state,
        formSubmitted: true,
      };
    case "CLEAR_FORM_SUBMITTED":
      return {
        ...state,
        formSubmitted: false,
      };
    default:
      return state;
  }
};

const countryFormatter = (data) => {
  return data.map(item=>({
    _id: item._id,
    name: item.name,
    status: item.status,
    modifiedOn: item.modifiedOn,
    modifiedBy: item.modifiedBy && item.modifiedBy.firstName
  }))
}

const dataParser = (data) => {
  return data.map((item, i) => ({
    _id: item._id,
    name: item.country,
    city: item.city,
    status: item.status,
    createdOn: item.createdOn,
    modifiedOn: item.updatedOn,
    modifiedBy: item.modifiedBy && item.modifiedBy.firstName
  }));
};
