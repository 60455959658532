import { map } from "lodash";
import moment from 'moment';
import {
  REQUEST_COLLECTION,
  RECEIVE_COLLECTION,
  FAILURE_COLLECTION,
} from "../actions/collectionAction";

const initialState = {
  collectionList: [],
  total: 0,
  searchParam: {},
  page: 1,
  loading: false,
  categoryById: {},
  error: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case RECEIVE_COLLECTION:
      return {
        ...state,
        collectionList: dataParser(payload.data),
        total: payload.data.length,
        loading: false,
      };
    case "STATUS_CHANGE_COLLECTION":
      return {
        ...state,
        loading: false,
      };
    case "DELETE_COLLECTION":
      return {
        ...state,
        loading: false,
      };
    case "UPDATE_COLLECTION":
      return {
        ...state,
        loading: false,
      };
    case "CREATE_COLLECTION":
      return {
        ...state,
        loading: false,
      };
    case REQUEST_COLLECTION:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case FAILURE_COLLECTION:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

const dataParser = (data) => {
  return data.map((item, i) => ({
    index: i + 1,
    _id: item._id,
    title: item.title,
    posts: item.posts,
    imageUrl: item.imageUrl?.original,
    createdOn: item.createdOn && moment.unix(item.createdOn).format('lll'),
    modifiedOn: item.modifiedOn && moment.unix(item.modifiedOn).format('lll'),
    modifiedBy: item.modifiedBy?.firstName,
    createdBy: item.createdBy?.firstName,
    status: item.status,
    url: item.url,
    description: item.description,
    ogTitle: item.ogTitle,
    seoTitle: item.seoTitle,
    ogDescription: item.ogDescription,
    seoDescription: item.seoDescription,
  }));
};
