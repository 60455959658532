import { combineReducers } from "redux";
import user from "./user";
import loading from "./loading";
import error from "./error";
import { reducer as toastrReducer } from "react-redux-toastr";
import { reducer as formReducer } from "redux-form";
import manageUser from "./manageUserReducer";
import category from "./categoryReducer";
import collection from "./collectionReducer";
import staticPage from "./staticPageReducer";
import headerMenu from "./contentConfigurationsFields/headerMenuReducer";
import subCategory from "./subCategoryReducer";
import tag from "./tagReducer";
import contentConfiguration from "./contentConfigurationsFields/headerMenuReducer";
import homePageFeaturePost from "./contentConfigurationsFields/homePageFeaturePostReducer";
import homePageBody from "./contentConfigurationsFields/homePageBodyReducer";
import categoryFeaturePost from "./contentConfigurationsFields/categoryFeaturePostReducer";
import globalBannerReducer from "./contentConfigurationsFields/globalBannerReducer";
import commentsReducer from "./comments";
import followersReducer from "./followers";
import recommendsReducer from "./recommends";
import managePost from "./managePostReducer";
import countryCity from "./countryCityList";
import subscriberReducer from "./subscriber";
import paymentReducer from "./paymentReducer";
import subscriptionReducer from './subscriptionReducer' 

export default combineReducers({
  user,
  manageUser,
  category,
  //loading,
  collection,
  subCategory,
  staticPage,
  tag,
  error,
  toastr: toastrReducer,
  form: formReducer,
  contentConfiguration,
  headerMenu,
  homePageFeaturePost,
  homePageBody,
  categoryFeaturePost,
  globalBannerReducer,
  commentsReducer,
  followersReducer,
  recommendsReducer,
  managePost,
  countryCity,
  paymentReducer,
  subscriberReducer,
  subscriptionReducer,
});
