import moment from "moment";

import {
  REQUEST_SUB_CATEGORY,
  RECEIVE_SUB_CATEGORY,
  CREATE_SUB_CATEGORY,
  GETBY_ID_SUB_CATEGORY,
  DELETE_SUB_CATEGORY,
  FAILURE_SUB_CATEGORY,
  STATUS_CHANGE_SUB_CATEGORY,
  UPDATE_SUB_CATEGORY,
} from "../actions/subCategoryAction";

const initialState = {
  subCategoryList: [],
  total: 0,
  searchParam: {},
  page: 1,
  loading: false,
  subCategoryById: {},
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_SUB_CATEGORY:
      return {
        ...state,
        subCategoryList: dataParser(action.payload.data.docs),
        total: action.payload.total || action.payload.data.length,
        loading: false,
      };

    case REQUEST_SUB_CATEGORY:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case CREATE_SUB_CATEGORY:
      return {
        ...state,
        // subCategoryList: [ action.payload.data, ...state.subCategoryList],
        total: state.total + 1,
        loading: false,
      };
    case DELETE_SUB_CATEGORY:
      return {
        ...state,
        // subCategoryList: removeCategoryById(action, state),
        // total: state.total - 1,
        loading: false,
      };
    case STATUS_CHANGE_SUB_CATEGORY:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_SUB_CATEGORY:
      return {
        ...state,
        // subCategoryList: updateCategoryById(action, state),
        loading: false,
      };
    case GETBY_ID_SUB_CATEGORY:
      return {
        ...state,
        subCategoryById: action.payload,
        loading: false,
      };
    case "SET_PAGE_NUMBER": {
      return {
        ...state,
        page: action.payload,
        loading: false,
      };
    }
    case "SET_SEARCH_PARAM_DATA":
      return {
        ...state,
        searchParam: action.payload.searchParam,
        page: action.payload.page,
        loading: false,
      };
    case FAILURE_SUB_CATEGORY:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

const dataParser = (data) => {
  return data.map((item, i) => ({
    index: i,
    _id: item._id,
    title: item.title,
    category: item.category,
    status: item.status,
    url: item.url,
    posts:item.posts,
    createdOn: item.createdOn ? moment.unix(item.createdOn).format("lll") : "",
    modifiedOn: item.modifiedOn ? moment.unix(item.modifiedOn).format("lll") : "",
    createdBy: item.createdBy?.firstName,
    modifiedBy: item.modifiedBy?.firstName,
    description: item.description,
    ogDescription: item.ogDescription,
    ogTitle: item.ogTitle,
    seoDescription: item.seoDescription,
    seoTitle: item.seoTitle
  }));
};
