const initialState = {
  headerMenuList: [],
  categoryList: [],
  subCategoryList: [],
  collectionList: [],
  staticPageList: [],
  loading: false,
  error: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "CONTENT_CONFIG_HEADERMENU_REQUEST_DATA":
      return {
        ...state,
        loading: true,
        error: false,
      };
    case "CONTENT_CONFIG_HEADERMENU_FAIL_REQUEST":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case "CONTENT_CONFIG_HEADERMENU_LIST":
      return {
        ...state,
        loading: false,
        error: false,
        headerMenuList: payload.data.headerMenu.map((item, index) => ({
          ...item,
          order: index,
          selectedValue: `${item.name},${item.id},${item.url}`,
        })),
      };
    case "CONTENT_CONFIG_CATEGORY_LIST":
      return {
        ...state,
        loading: false,
        error: false,
        categoryList: dataSorter(payload.data.filter(item=> item.posts)),
      };
    case "CONTENT_CONFIG_SUBCATEGORY_LIST":
      return {
        ...state,
        loading: false,
        error: false,
        subCategoryList: dataSorter(payload.data),
      };
    case "CONTENT_CONFIG_COLLECTION_LIST":
      return {
        ...state,
        loading: false,
        error: false,
        collectionList: dataSorter(payload.data),
      };
    case "CONTENT_CONFIG_STATIC_PAGE_LIST":
      return {
        ...state,
        loading: false,
        error: false,
        staticPageList: dataSorter(payload.data),
      };
    default:
      return state;
  }
};

function dataSorter(data) {
  data.unshift({ name: "Select Type", value: "" });
  return data.map((item) => ({
    name: item.name || item.title,
    value: `${item.name || item.title},${item._id || item.id},${
      item.url || ""
    }`,
    posts: item.posts || undefined,
    url: item.url || undefined,
    id: item._id || item.id || undefined,
  }));
}