import { get, map, filter, merge } from "lodash";

import {
  REQUEST_COLLECTION,
  RECEIVE_COLLECTION,
  FAILURE_COLLECTION,
} from "../actions/collectionAction";

const initialState = {
  staticPagesList: [],
  staticPageDetails: {},
  formSubmited: false,
  total: 0,
  searchParam: {},
  page: 1,
  loading: false,
  categoryById: {},
  error: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case RECEIVE_COLLECTION:
      return {
        ...state,
        staticPagesList: payload.data,
        total: payload.data.length,
        loading: false,
      };
    case "STATUS_CHANGE_COLLECTION":
      return {
        ...state,
        loading: false,
      };
    case "DELETE_COLLECTION":
      return {
        ...state,
        loading: false,
      };
    case "UPDATE_COLLECTION":
      return {
        ...state,
        loading: false,
      };
    case "CREATE_COLLECTION":
      return {
        ...state,
        loading: false,
      };
    case REQUEST_COLLECTION:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case FAILURE_COLLECTION:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case "GET_STATIC_PAGE_DETAIL":
      return {
        ...state,
        staticPageDetails: payload.data,
        loading: false,
      };
    case "UPDATE_STATIC_PAGE_DETAIL":
      return {
        ...state,
        formSubmited: true,
      };
    case "CLEAR_STATIC_PAGE_DETAILS":
      return {
        ...state,
        staticPageDetails: {},
      };
    default:
      return state;
  }
};
