const dev = {
  api: {
    //  HOST: 'http://localhost:9000',
    // HOST: 'https://api.transfin.in',
    // HOST: 'http://167.71.225.3:9000',
    // HOST: 'http://167.71.225.3:9000',
    HOST: 'https://transfinapi.techsuperior.in',
    VERSION: '/v1/api/'
  },
  auth: {
    GRANT_TYPE: 'password',
    CLIENT_ID: 'client_admin',
    CLIENT_SECRET: 'client_secret'
  }
};

const prod = {
  api: {
    HOST: 'https://transfinapi.techsuperior.in',
    VERSION: '/v1/api/'
  },
  auth: {
    GRANT_TYPE: 'password',
    CLIENT_ID: 'client_admin',
    CLIENT_SECRET: 'client_secret'
  }
};

const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev;
// const config = process.env.REACT_APP_STAGE === 'production' ? dev : prod;

const WEB_BASE_URL = process.env.REACT_APP_STAGE === 'production' ? 'https://transfinapi.techsuperior.in' : 'https://transfinapi.techsuperior.in' //'https://dev.transfin.in'

export default {
  // Add common config values here
  APP_NAME: 'transfin-admin',
  MAX_ATTACHMENT_SIZE: 5000000,
  WEB_BASE_URL,
  ...config
};
