import * as moment from "moment";

const initialState = {
  paymentList: [],
  loading: false,
  error: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "PAYMENT_REQUEST_DATA":
      return {
        ...state,
        loading: true,
        error: false,
      };
    case "PAYMENT_FAIL_REQUEST":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case "GET_PAYMENT_DATA":
      return {
        ...state,
        loading: false,
        error: false,
        paymentList: dataParser(payload.data),
      };
    default:
      return state;
  }
};

const dataParser = (data) => {
  return data.map((item, i) => ({
    index: i + 1,
    email:item.email,
    phone:item.phone,
    entity:item.entity,
    subscriptionId:item.subscriptionId,
    paymentId: item.paymentId,
    orderId:item.orderId,
    amount:item.amount,
    status:item.status,
    method:item.method,
    createdOn: item.createdOn && item.createdOn ? moment.unix(item.createdOn).format("lll") : item.createdOn,
  }));
};