import $ from 'jquery';
import React, { Component } from 'react';
import { correctHeight, detectBody } from '../../theme/helpers/helpers';
import { Link, withRouter } from 'react-router-dom';
import img404 from '../../assets/images/404.png';
import logo from '../../assets/images/logo.png';

import '../../assets/dependencies';

export default class NotFound extends Component {
  componentDidMount() {
    // eslint-disable-next-line func-names
    $(window).bind('load resize', function() {
      correctHeight();
      detectBody();
    });
  }

  render() {
    return (
      <div id="wrapper">

       {/* <nav className={`navbar header-top fixed-top navbar-expand-lg navbar-light navbar-scroll white-bg`}>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" />
            </button>
            <Link className="nav-link" to="">
              <img alt="show" className="img-circle logo" src={logo}/>
            </Link>
       </nav>      */}

        <div className="gray-bg">

          <div className="container-fluid not-found">
            <div className="row">
              <div className="col-md-12 text-center">
                    <div className="top-icon">
                       <img src={img404} />
                    </div>
                    <div className="oops">
                        <h2 className="oops-title">
                            Ooops! Page not found
                        </h2>
                        <b>Unfortunately, This document is not available !</b>
                        <p className="oops-details">
                            The page you are looking for might have moved, been renamed or perhaps never existed.
                        </p>
                        <Link  className="back-to-home" to="/" >Back Home</Link>
                    </div>
                    <h1 className="logo-name"> TRANSFIN. Admin Panel </h1>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}
