import API from "../../helpers/api";
import { toastr } from "react-redux-toastr";
import { get } from "lodash";

export const REQUEST_SUB_CATEGORY = "REQUEST_SUB_CATEGORY";
export const RECEIVE_SUB_CATEGORY = "RECEIVE_SUB_CATEGORY";
export const CREATE_SUB_CATEGORY = "CREATE_SUB_CATEGORY";
export const GETBY_ID_SUB_CATEGORY = "GETBY_ID_SUB_CATEGORY";
export const DELETE_SUB_CATEGORY = "DELETE_SUB_CATEGORY";
export const STATUS_CHANGE_SUB_CATEGORY = "STATUS_CHANGE_SUB_CATEGORY";
export const UPDATE_SUB_CATEGORY = "UPDATE_SUB_CATEGORY";
export const FAILURE_SUB_CATEGORY = "FAILURE_SUB_CATEGORY";

function requestSubCategory() {
  return {
    type: REQUEST_SUB_CATEGORY,
  };
}
function errorSubCategory(err) {
  let data = get(err, "response.data", null);
  data = !!data ? data : get(err, "response");
  data = !!data ? data : err;
  toastr.error(
    "TRANSFIN. Admin Panel  ",
    data.message ? data.message : "Sub Category Request Failed! "
  );

  return (dispatch, getState) => {
    dispatch({
      type: FAILURE_SUB_CATEGORY,
      payload: err,
    });
  };
}

export function fetchSubCategoryList(requestedParams) {
return (dispatch, getState) => {
    dispatch(requestSubCategory());

    API.apiGet("getListSubCategory", requestedParams)
      .then((response) => {
        if (response.data.status == "SUCCESS") {
          dispatch({ type: "RECEIVE_SUB_CATEGORY", payload: response.data });
        }
      })
      .catch((error) => {
        errorSubCategory(error)(dispatch);
      });
  };
}

export function addSubCategoryList(requestedData) {
  return (dispatch, getState) => {
    dispatch(requestSubCategory());

    API.apiPost("createListSubCategory", requestedData)
      .then((response) => {
        if (response.data.status == "SUCCESS") {
          dispatch({ type: "CREATE_SUB_CATEGORY", payload: response.data });
        }
      })
      .catch((error) => {
        errorSubCategory(error)(dispatch);
      });
  };
}

export function changeStatusSubCategoryId(requestedIds) {
  return (dispatch, getState) => {
    dispatch(requestSubCategory());

    API.apiPut("changeStatusSubCategory", requestedIds)
      .then((response) => {
        if (response.data.status == "SUCCESS") {
          dispatch({
            type: "STATUS_CHANGE_SUB_CATEGORY",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        errorSubCategory(error)(dispatch);
      });
  };
}

export function deleteSubCategoryId(requestedIds) {
  return (dispatch, getState) => {
    dispatch(requestSubCategory());

    API.apiPut("changeStatusSubCategory", requestedIds)
      .then((response) => {
        if (response.data.status == "SUCCESS") {
          dispatch({ type: "DELETE_SUB_CATEGORY", payload: response.data });
        }
      })
      .catch((error) => {
        errorSubCategory(error)(dispatch);
      });
  };
}

export function updateSubCategoryId(requestedId, updateData) {
  return (dispatch, getState) => {
    dispatch(requestSubCategory());

    API.apiPutUrl("getUpdateDelSubCategoryById", requestedId, updateData)
      .then((response) => {
        if (response.data.status == "SUCCESS") {
          dispatch({ type: "UPDATE_SUB_CATEGORY", payload: response.data });
        }
      })
      .catch((error) => {
        errorSubCategory(error)(dispatch);
      });
  };
}

export function getSubCategoryId(requestedId) {
  return (dispatch, getState) => {
    dispatch(requestSubCategory());

    API.apiGet("createListSubCategory", requestedId)
      .then((response) => {
        if (response.data.status == "SUCCESS") {
          dispatch({ type: "GETBY_ID_SUB_CATEGORY", payload: response.data });
        }
      })
      .catch((error) => {
        errorSubCategory(error)(dispatch);
      });
  };
}

export function fetchSubCategoryData(requestedParams) {
  return (dispatch, getState) => {
    return dispatch(fetchSubCategoryList(requestedParams));
  };
}
