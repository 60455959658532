import { get, map, filter, merge } from "lodash";

import {
  REQUEST_CATEGORY,
  RECEIVE_CATEGORY,
  CREATE_CATEGORY,
  GETBY_ID_CATEGORY,
  DELETE_CATEGORY,
  FAILURE_CATEGORY,
  STATUS_CHANGE_CATEGORY,
  UPDATE_CATEGORY,
} from "../actions/categoryAction";

const initialState = {
  categoryList: [],
  total: 0,
  searchParam: {},
  page: 1,
  loading: false,
  categoryById: {},
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_CATEGORY:
      return {
        ...state,
        categoryList: bodyParser(action.payload.data.docs),
        total: action.payload.total || action.payload.data.length,
        loading: false,
      };

    case REQUEST_CATEGORY:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case CREATE_CATEGORY:
      return {
        ...state,
        // categoryList: [ action.payload.data, ...state.categoryList],
        total: state.total + 1,
        loading: false,
      };
    case DELETE_CATEGORY:
      return {
        ...state,
        // categoryList: removeCategoryById(action, state),
        // total: state.total - 1,
        loading: false,
      };
    case STATUS_CHANGE_CATEGORY:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_CATEGORY:
      return {
        ...state,
        // categoryList: updateCategoryById(action, state),
        loading: false,
      };
    case GETBY_ID_CATEGORY:
      return {
        ...state,
        categoryById: action.payload,
        loading: false,
      };
    case "SET_PAGE_NUMBER": {
      return {
        ...state,
        page: action.payload,
        loading: false,
      };
    }
    case "SET_SEARCH_PARAM_DATA":
      return {
        ...state,
        searchParam: action.payload.searchParam,
        page: action.payload.page,
        loading: false,
      };
    case FAILURE_CATEGORY:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

const bodyParser = (data) => {
  return data.map((item) => ({
    ...item,
    length: item ? item.subcategories ? item.subcategories.length : 0 : 0,
    imageUrl: item.imageUrl?.original,
  }));
};