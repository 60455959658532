import moment from 'moment'

const initialState = {
  recommendsList: [],
  loading: false,
  error: false,
  total: 0,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "RECOMMENDS_REQUEST_DATA":
      return {
        ...state,
        loading: true,
        error: false,
      };
    case "RECOMMENDS_FAIL_REQUEST":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case "GET_RECOMMENDS_DATA":
      return {
        ...state,
        loading: false,
        error: false,
        recommendsList: dataParser(payload.data.docs),
        total: payload.data.total,
      };
    default:
      return state;
  }
};

const dataParser = (data) => {
  return data.map((item, i) => ({
    index: i + 1,
    _id: item._id,
    postTitle: item.article?.title,
    postAuthorName: item.article?.createdBy?.firstName,
    url: item.article?.url,
    userName: item.createdBy?.firstName,
    status: item.status,
    createdOn: item.createdOn && moment.unix(item.createdOn).format("lll"),
    modifiedOn: item.modifiedOn && moment.unix(item.modifiedOn).format("lll"),
  }));
};
