export const apiKeys = {
  rssList: "app/rss/list",
  signUp: "users/auth/register",
  login: "users/auth/login",
  imageUpload: "app/media/upload",
  getToken: "users/auth/access/Token",
  createListCategory: "app/category",
  getListSubCategory: "app/subcategories/list",
  sendOtp: "users/auth/generate/otp",
  validateOtp: "users/auth/validate/otp",
  getArticlesByCode: "/app/configurations/articles/search",
  fetchPostByCategory: "/app/configurations/category/articles/search",
  fetchGlobalBannerData: "/app/configurations/global/banner",
  getStaticPages: "app/configurations/static/pages",
  createListSubCategory: "app/subcategories",
  getUpdateDelCategoryById: "/app/category",
  getUpdateDelSubCategoryById: "/app/subcategories/",
  changeStatusCategory: "/app/category/update/status",
  changeStatusCollection: "/app/collections/update/status",
  changeStatusSubCategory: "/app/subcategories/update/status",
  changeStatusTag: "/app/tags/status/update",
  socialLogin: "users/auth/social/login",
  createProfile: "users/auth/create/profile",
  newsletterSubscribe: "users/newsletter/subscribe",
  series: "series/series-list",
  seriesList: "app/collections",

  menuItems: "app/menu/items",
  seriesArticlePost: "app/articles",
  recentCollections: "app/collections/recent/updated",
  commonFetchUrl: "app/common/fetch",
  fetchPostDetail: "app/articles/",
  createCollection: "app/collections",
  createTag: "app/tags",
  updateDelCollectionById: "app/collections/",
  authorsTrending: "users/authors/trending",
  userOperations: "users/article/operations",
  getHeaderMenuOfContentConfiguration: "app/configurations/header/menu",

  /* Tags */
  tagList: "app/tags",
  searchTagList: "app/tags/search",
  updateDelTagById: "app/tags",
  updateTagDetails: "app/tags",

  /* manage posts */
  getArticlePostList: "admin/panel/articles",
  getArticlePostTrashList: "admin/panel/trash/articles",
  updateArticlesStatus: "app/articles/change/status",
  changeArticlesStatus: "app/articles/status/update",
  getSubcategoryByCategory: "app/subcategories/category",
  saveAsDraftPost: "app/articles/draft",
  collectionList: "app/collections/list",
  getExportedList: "app/articles/exports",
  isPostEditable: "app/articles/edit/status",
  
  /* login  */
  login: "admin/login",
  verifyOtp: "admin/login/validate",
  logout: "/users/auth/logout",
  /* login end  */

  /* country & city  */
  getCountryList: "app/countries",
  getCityList: "app/cities",
  addLocation: "app",
  updateLocation: "app",
  changeLocationStatus: "app",
  /* country & city end  */

  /* manage users  */
  fetchUsersList: "users/list",
  getUserData: "admin/users/",
  updateUser: "users/profile",
  addUser: "admin/users",
  changeStatus: "admin/users",
  updatePermission: "users/profile/update/permissions",
  fetchAllUsers: "users/all",
  /* manage users end */

  /* static Page */
  changeStaticPageStatus: "app/configurations/static/pages/status/update",
  fetchStaticPageDetails: "app/configurations/static/pages",
  updateStaticPageDetails: "app/configurations/static/pages",
  addStaticPage: "app/configurations/static/pages",
  /* static Page end */

  /* comments  */
  commentsPost: "app/comments",
  updateCommentStatus: "app/comments/update/status",
  /* comments end */

  /* recommends  */
  articlesLikes: "admin/articles/likes/list",
  articlesBookmark: "app/articles/bookmark",
  articlesTrending: "app/articles/trending",
  /* recommends end */

  /* followers  */
  followersList: "users/profile/follower/list",
  /* followers end */

  /*contentConfiguration */
  getCategoryListForHeaderMenu: "app/category/active",
  getSubCategoryListForHeaderMenu: "app/subcategories/active",
  getCollectionListForHeaderMenu: "app/collections",
  fetchHomePageFetauredPost: "/app/configurations/homepage/featured/articles",
  getStaticPageListForHeaderMenu: "app/configurations/active/static/pages",
  updateContentConfiguation: "app/configurations/homepage/body",
  fetchContentConfiguation: "app/configurations/homepage/body/details",
  fetchHomepage: "app/content/configuration",
  getHeaderMenuOfContentConfiguration: "app/configurations/header/menu",
  getArticlesByCode: "/app/configurations/articles/search",
  fetchPostByCategory: "/app/configurations/category/articles/search",
  fetchGlobalBannerData: "/app/configurations/global/banner",
  getStaticPages: "app/configurations/static/pages",
  fetchCategoryFeaturePost:
    "/app/configurations/homepage/category/articles/contents",
  saveCategoryMenuPost: "/app/configurations/homepage/category/articles",
  /*contentConfiguration end */

  /* subscribers */
  subscriber: "users/newsletter/subscriber",
  /* subscribers end */
  
  /* subscription */
  subscription: "newsletter/subscriptions/list",
  utmRecords: "/app/utm/tracker/list",
  utmDownload: "app/utm/tracker/download",
  subscriberTrackRecords: "newsletter/subscriptions/track",
  /* subscription end */

  /* payment*/
  payment: "newsletter/subscriptions/payment/list"
  /* payment end*/
};
