import {
    REQUEST_MANAGE_POST,
    RECEIVE_MANAGE_POST,
    CATEGORY_LIST_MANAGE_POST,
    SUBCATEGORY_LIST_MANAGE_POST,
    COLLECTION_LIST_MANAGE_POST,
    TAGS_LIST_MANAGE_POST,
    REQUEST_POST_DETAILS,
    RECEIVE_POST_DETAILS,
    RECEIVE_MANAGE_TRASH_POST,
    STATUS_CHANGE_POST,
    FAILURE_MANAGE_POST,
    SAVE_MANAGE_POST,
    SET_PAGE_NUMBER_MANAGE_POST,
    SET_SEARCH_PARAM_MANAGE_POST,
    EMPTY_SUBCATEGORY_LIST,
    REQUEST_MANAGE_LIST,
} from "../actions/managePostActions";

const initialState = {
    categoryList: [],
    subCategoryList: [],
    collectionList: [],
    exportedList: [],
    tagsList: [],
    postDetails: {},
    managePostList: [],
    managePostTrashList: [],
    total: 0,
    searchParam: {},
    page: 1,
    loading: false,
    loadingPost: false,
    fetching: false,
    error: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case REQUEST_MANAGE_POST:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case REQUEST_MANAGE_LIST:
            return {
                ...state,
                loadingPost: true,
                error: false,
            }
        case REQUEST_POST_DETAILS:
            return {
                ...state,
                postDetails: {},
                fetching: true,
                error: false,
            };    
        case FAILURE_MANAGE_POST:
            return {
                ...state,
                loading: false,
                loadingPost: false,
                fetching: false,
                postDetails: {},
                error: payload,
            };
        case SAVE_MANAGE_POST: 
            return {
                ...state,
                loading: false,
                fetching: false,
                error: payload,
            };    
        case RECEIVE_MANAGE_POST:
            return {
                ...state,
                managePostList: payload.docs,
                total: payload.total || payload.docs.length,
                loadingPost: false,
            };
        case RECEIVE_MANAGE_TRASH_POST:
            return {
                ...state,
                managePostTrashList: payload.docs,
                total: payload.total || payload.docs.length,
                loadingPost: false,
            };
        case RECEIVE_POST_DETAILS:
            return {
                ...state,
                postDetails: payload,
                fetching: false,
            };   
        case SET_PAGE_NUMBER_MANAGE_POST: 
            return {
                ...state,
                page: payload,
                loading: false,
            };
        case SET_SEARCH_PARAM_MANAGE_POST:
            return {
                ...state,
                searchParam: payload.searchParam,
                page: payload.page,
                loading: false,
            };
        case CATEGORY_LIST_MANAGE_POST:
            return {
                ...state,
                loading: false,
                error: false,
                categoryList: dataSorter(payload.data, 'Category'),
            };
        case SUBCATEGORY_LIST_MANAGE_POST:
            return {
                ...state,
                loading: false,
                error: false,
                subCategoryList: dataSorter(payload.data, 'Sub Category'),
            };
        case TAGS_LIST_MANAGE_POST:
            return {
                ...state,
                loading: false,
                error: false,
                tagsList: dataSorter(payload.data.docs, 'Tag'),
            };
        case COLLECTION_LIST_MANAGE_POST:
            return {
                ...state,
                loading: false,
                error: false,
                collectionList: dataSorter(payload.data.docs, 'Collection'),
            };
        case EMPTY_SUBCATEGORY_LIST:
            return {
                ...state,
                subCategoryList: []
            }
        default:
            return state;
    }
};

function dataSorter(data, type) {
    if(type) { data.unshift({ name: `Select ${type}`, value: "", posts: 1, })}
    return data.map((item) => ({
        name: item.name || item.title,
        posts: item.posts || undefined,
        label: item.name || item.title,
        value: item._id || item.id,
        url: item.url || undefined,
        imageUrl: item.imageUrl ? item.imageUrl.original : '',
        id: item._id || item.id || undefined,
    }));
}